<template>
      <div class="labelGenEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'labelGen', params: {} }">
          Label Gen
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'labelGenEdit', params:{id: id} }">
            Edit Label Gen
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Label Gen</v-card-title>
          <v-card-text>
            <labelGen-form :id="id"></labelGen-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import LabelGenForm from './LabelGenForm.vue';

    export default {
      name: 'labelGen',
      data () {
        return {
          id: null
        }
      },
      components: {
        LabelGenForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    